import { DayOfWeek } from 'hcl-lib';

export enum MediaType {
  Art = "Art",
  Audio = "Audio",
  Image = "Image",
  PDF = "PDF",
  Template = "Template",
  Video = "Video",
  Unknown = "Unknown",
  Dynamic_Template = "Dynamic_Template"
}

export enum MediaTypePerm {
  Art = "scr:media:read:art",
  Audio = "scr:media:read:audio",
  Image = "scr:media:read:image",
  PDF = "scr:media:read:pdf",
  Template = "scr:media:read:template",
  Video = "scr:media:read:video",
  Unknown = "Unknown",
  Dynamic_Template = "scr:media:read:dynamic-template"
}

export enum MediaStatus {
  Processing = "Processing",
  Success = "Success",
  Failed = "Failed"
}

export enum ExternalMediaSource {
  PosterMyWall = "PosterMyWall"
}

export enum MediaOrientation {
  LANDSCAPE = "LANDSCAPE",
  PORTRAIT = "PORTRAIT",
  SQUARE = "SQUARE",
  UNDEFINED = "UNDEFINED"
}

export enum MediaErrorKey {
  GENERIC = "GENERIC",
  UPDATING = "UPDATING",
  ENCODING = "ENCODING",
  UPLOAD_CANCELLED = "UPLOAD_CANCELLED",
  GENERATING_DYNAMIC_TEMPLATE = "GENERATING_DYNAMIC_TEMPLATE",
  GENERATING_LEGACY_TEMPLATE = "GENERATING_LEGACY_TEMPLATE",
  GENERATING_THUMBNAIL = "GENERATING_THUMBNAIL",
  EMPTY_MEDIA = "EMPTY_MEDIA",
  DOWNSCALING = "DOWNSCALING",
  UPLOADING = "UPLOADING",
  UPLOADING_THUMBNAIL = "UPLOADING_THUMBNAIL"
}

export interface Media {
  id: string
  createdAt?: Date
  createdBy?: string
  createdByUserName?: string
  modifiedAt?: Date
  modifiedBy?: string
  modifiedByUserName?: string
  customerId: string
  name: string
  public: boolean
  type: MediaType
  status: MediaStatus
  url: string
  thumbnailUrl: string
  s3FilePath: string
  tagIds?: string[]
  displayTimeInSec: number
  validityStart?: Date
  validityEnd?: Date
  validityDays?: Set<DayOfWeek>
  templateId?: string
  directoryId?: string
  inheritableTemplate?: boolean
  visibleForDomains?: string[]
  visibleForAppIds?: string[]
  externalMediaSource?: ExternalMediaSource
  externalMediaId?: string
  errorKey?: MediaErrorKey
  orientation?: MediaOrientation
  width?: number
  height?: number
}

export interface EncoderConfig {
  maxCpu: string,
  maxMem: string
}
