export interface TemplateVariable {
  name: string,
  objectIdx: number,
  type: 'IMAGE' | 'TEXT',
  value: string
}

export enum TemplateType {
  CLASSIC = "CLASSIC",
  LEGACY = "LEGACY",
  HUMECANVA = "HUMECANVA"
}

export interface Template {
  type: TemplateType
  id?: string,
  customerId: string,
  name: string,
  datas: string,
  variables: TemplateVariable[],
  tagIds?: string[],
  mediaId?: string,
  inheritable: boolean,
  templateId?: string,
  width: number,
  height: number,
  legacy: boolean,
  legacyFormat?: string,
  mediaIds?: string[],
  thumbnailUrl?: string
}
