import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { catchError, map } from "rxjs/operators"

@Injectable()
export class LoaderService {
  private static loaderEnabled$ = new BehaviorSubject<boolean>(false)
  private static blockingLoaderEnabled$ = new BehaviorSubject<boolean>(false)

  private static currentCount = 0
  private static blockingCurrentCount = 0

  constructor() { }

  get loaderEnabledObservable() {
    return LoaderService.loaderEnabled$
  }

  get blockingLoaderEnabledObservable() {
    return LoaderService.blockingLoaderEnabled$
  }

  public static showLoader(blockingLoader: boolean = false) {
    if (blockingLoader) {
      LoaderService.blockingCurrentCount++
      LoaderService.blockingLoaderEnabled$.next(true)
    } else {
      LoaderService.currentCount++
      LoaderService.loaderEnabled$.next(true)
    }
  }

  public static hideLoader(blockingLoader: boolean = false) {
    if (blockingLoader) {
      LoaderService.blockingCurrentCount--
      if (LoaderService.blockingCurrentCount === 0) {
        LoaderService.blockingLoaderEnabled$.next(false)
      }
    } else {
      LoaderService.currentCount--
      if (LoaderService.currentCount === 0) {
        LoaderService.loaderEnabled$.next(false)
      }
    }
  }
}

export function Loader(blocking = false): MethodDecorator {

  return function(target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor) {
    const original = descriptor.value

    descriptor.value = function() {

      LoaderService.showLoader(blocking)

      return original.apply(this, arguments)
        .pipe(
          map((res) => {
            LoaderService.hideLoader(blocking)
            return res
          }),
          catchError((err) => {
            LoaderService.hideLoader(blocking)
            throw err
          })
        )
    }
    return descriptor
  }

}
