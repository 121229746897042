import {DOCUMENT} from '@angular/common';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Inject, Injectable, NgZone} from '@angular/core';
import {HttpShowError, InvocationResult, Loader, QueryScope} from "hcl-lib";
import {HttpUtil} from 'projects/hcl-portal/src/app/common/util/http.util';
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {environment} from "../../../../environments/environment";
import {TemplateDto} from "../../interfaces/dto/template-dto";
import {Template, TemplateVariable} from "../../interfaces/template";

export interface TemplateSize {
  width: number,
  height: number
}

export interface TemplateComponent {
  openMediaPicker(): Observable<MediaForTemplate | null>
  openTemplateListPage(): any
  openSizeEditor(): Observable<TemplateSize>
  hasPermission(perm: string): Observable<Boolean>
}

export interface MediaForTemplate {
  id: string,
  name: string,
  url: string
}

interface ScreenlabTemplateEditorIframe {
  setTemplateObjectText(objIdx: number, text: string): void
  setTemplateObjectImageMedia(objIdx: number, media: any): void
  selectTemplateObject(objIdx: number): void
  saveTemplate(): void
}

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  private component: TemplateComponent | null = null
  private previewOnly: boolean = false
  private creation: boolean = false
  private instantiation: boolean = false
  name: string = "Template"
  private template: Template | null = null
  private size: TemplateSize | null = null

  constructor(
    private httpClient: HttpClient,
    private httpUtil: HttpUtil,
    private ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document
  ) {
    // do nothing
  }

  @Loader(true)
  @HttpShowError(true)
  createTemplate(template: Template, generatedPng: string): Observable<Template | null> {
    const url = `${environment.mediaApiBaseUrl}/templates`
    const templateDto: TemplateDto = {
      type: template.type,
      name: template.name,
      datas: template.datas,
      variables: template.variables,
      tagIds: [],
      inheritable: !this.isInstantiation(),
      generatedPng: generatedPng,
      templateId: this.isInstantiation() ? this.getEditedTemplate()?.id : undefined,
      width: template.width,
      height: template.height,
      legacy: template.legacy,
      legacyFormat: template.legacyFormat,
    }
    return this.httpClient.post(url, templateDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data as Template
      })
    )
  }

  @Loader(true)
  @HttpShowError(true)
  createTemplateHumecanva(template: Template, generatedPng: string): Observable<Template | null> {
    const url = `${environment.mediaApiBaseUrl}/templates`
    const templateDto: TemplateDto = {
      type: template.type,
      name: template.name,
      datas: template.datas,
      variables: template.variables,
      tagIds: [],
      inheritable: template.inheritable,
      generatedPng: generatedPng,
      templateId: template.templateId,
      width: template.width,
      height: template.height,
      legacy: template.legacy,
      legacyFormat: template.legacyFormat,
    }
    return this.httpClient.post(url, templateDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data as Template
      })
    )
  }

  @Loader()
  @HttpShowError()
  getTemplatesWithPaging(
    page: Number,
    perPage: Number,
    tagIds: string[] | undefined = undefined,
    inheritable: boolean | undefined = undefined,
    queryScope: QueryScope | undefined = undefined,
    mediaId: string | undefined = undefined
  ): Observable<InvocationResult> {
    const url = `${environment.mediaApiBaseUrl}/templates`
    let queryParams = new HttpParams()
      .set('perPage', perPage.toString())
      .set('page', page.toString())
    if (tagIds) {
      tagIds.forEach(id => {
        queryParams = queryParams.append('tagIds', id);
      })
    }
    if (inheritable == true || inheritable == false) {
      queryParams = queryParams.append('inheritable', inheritable.toString())
    }
    if (queryScope) {
      queryParams = queryParams.append('queryScope', queryScope);
    }
    if (mediaId) {
      queryParams = queryParams.append('mediaId', mediaId);
    }
    return this.httpClient.get(url, { params: queryParams }).pipe(
      map((res: any) => {
        return (res as InvocationResult)
      })
    )
  }

  @Loader()
  @HttpShowError()
  getTemplate(
    id: string,
    queryScope: QueryScope | undefined = undefined
  ): Observable<Template> {
    const url = `${environment.mediaApiBaseUrl}/templates/${id}`
    let queryParams = new HttpParams()
    if (queryScope) {
      queryParams = queryParams.append('queryScope', queryScope);
    }
    return this.httpClient.get(url, { params: queryParams }).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @Loader(true)
  @HttpShowError(true)
  updateTemplate(template: Template, generatedPng: string): Observable<Template | null> {
    const url = `${environment.mediaApiBaseUrl}/templates/${template.id}`
    const templateDto: TemplateDto = {
      type: template.type,
      id: template.id,
      customerId: template.customerId,
      datas: template.datas,
      variables: template.variables,
      name: template.name,
      tagIds: template.tagIds,
      inheritable: template.inheritable,
      generatedPng: generatedPng,
      templateId: this.instantiation ? template.id : undefined,
      width: template.width,
      height: template.height,
      legacy: template.legacy,
      legacyFormat: template.legacyFormat
    }
    return this.httpClient.put(url, templateDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @Loader(true)
  @HttpShowError(true)
  updateTemplateHumecanva(template: Template, generatedPng: string): Observable<Template | null> {
    const url = `${environment.mediaApiBaseUrl}/templates/${template.id}`
    const templateDto: TemplateDto = {
      type: template.type,
      id: template.id,
      customerId: template.customerId,
      datas: template.datas,
      variables: template.variables,
      name: template.name,
      tagIds: template.tagIds,
      inheritable: template.inheritable,
      generatedPng: generatedPng,
      templateId: template.id,
      width: template.width,
      height: template.height,
      legacy: template.legacy,
      legacyFormat: template.legacyFormat
    }
    return this.httpClient.put(url, templateDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @Loader()
  @HttpShowError()
  deleteTemplate(id: String) {
    const url = `${environment.mediaApiBaseUrl}/templates/${id}`
    return this.httpClient.delete(url).pipe(
      catchError(this.httpUtil.handleHttpError)
    )
  }

  isShowCancel(): boolean {
    return !this.isPreviewOnly() && false
  }

  isShowDocumentSettings(): boolean {
    return !this.isPreviewOnly() && false
  }

  isShowPlugins(): boolean {
    return !this.isPreviewOnly() && false
  }

  isShowTimeline(): boolean {
    return !this.isPreviewOnly() && false
  }

  isShowVideo(): boolean {
    return !this.isPreviewOnly() && false
  }

  isConfigureLock(): boolean {
    return this.isPreviewOnly() || false
  }

  isPreviewOnly(): boolean {
    return this.previewOnly
  }

  setPreviewOnly(previewOnly: boolean) {
    this.previewOnly = previewOnly
  }

  getColors() {
    return ["D2041B", "06858C", "8EBA43", "F5A219", "F27612", "730237", "F05152", "A40E0C", "00AEDE", "1A7A14"]
  }

  getFontFamilies() {
    return {
      "Arial": "Arial",
      "Arial Black": "Arial Black",
      "Anton": "Anton",
      "Arimo": "Arimo",
      "Arvo": "Arvo",
      "Asap": "Asap",
      "Axia-Bold": "Axia-Bold",
      "Axia-BoldItalic": "Axia-BoldItalic",
      "Axia-Italic": "Axia-Italic",
      "Axia-Regular": "Axia-Regular",
      "Caviar Dreams": "Caviar Dreams",
      "Champagne & Limousines": "Champagne & Limousines",
      "Changa One": "Changa One",
      "Comfortaa": "Comfortaa",
      "Comic Sans MS": "Comic Sans MS",
      "Cookie": "Cookie",
      "Courier New": "Courier New",
      "Dancing Script": "Dancing Script",
      "DejaVu Sans": "DejaVu Sans",
      "Didact Gothic": "Didact Gothic",
      "Galada": "Galada",
      "Georgia": "Georgia",
      "Impact": "Impact",
      "Imprima": "Imprima",
      "Josefin Slab": "Josefin Slab",
      "Liberation Sans": "Liberation Sans",
      "Liberation Sans Narrow": "Liberation Sans Narrow",
      "Lobster": "Lobster",
      "Lobster Two": "Lobster Two",
      "Montserrat": "Montserrat",
      "Montserrat-ExtraBold": "Montserrat-ExtraBold",
      "Montserrat-ExtraLight": "Montserrat-ExtraLight",
      "Montserrat-Light": "Montserrat-Light",
      "Montserrat-Medium": "Montserrat-Medium",
      "Montserrat-SemiBold": "Montserrat-SemiBold",
      "Old Standard TT": "Old Standard TT",
      "Open Sans": "Open Sans",
      "Patua One": "Patua One",
      "Questrial": "Questrial",
      "Roboto": "Roboto",
      "Roboto-Black": "Roboto-Black",
      "Roboto-BlackItalic": "Roboto-BlackItalic",
      "Roboto-Bold": "Roboto-Bold",
      "Roboto-BoldItalic": "Roboto-BoldItalic",
      "Roboto-Italic": "Roboto-Italic",
      "Roboto-Light": "Roboto-Light",
      "Roboto-LightItalic": "Roboto-LightItalic",
      "Roboto-Medium": "Roboto-Medium",
      "Roboto-MediumItalic": "Roboto-MediumItalic",
      "Roboto-Regular": "Roboto-Regular",
      "Roboto-Thin": "Roboto-Thin",
      "RobotoCondensed-Bold": "RobotoCondensed-Bold",
      "SourceSansPro": "SourceSansPro",
      "Times New Roman": "Times New Roman",
      "Trebuchet MS": "Trebuchet MS",
      "Verdana": "Verdana"
    }
  }

  getCanvas() {
    return this.template == null ? this.getDefaultCanvas() : this.template.datas
  }

  getEditedTemplate() {
    return this.template
  }

  setEditedTemplate(template: Template | null) {
    this.template = template
  }

  isCreation(): boolean {
    return this.creation
  }

  setCreation(creation: boolean) {
    this.creation = creation
  }

  isInstantiation(): boolean {
    return this.instantiation
  }

  setInstantiation(instantiation: boolean) {
    this.instantiation = instantiation
  }

  getDefaultCanvas() {
    return {
      "version": "2.4.6",
      "objects": [
        {
          "type": "rect",
          "version": "2.4.6",
          "originX": "left",
          "originY": "top",
          "left": 0,
          "top": 0,
          "width": this.getSize().width,
          "height": this.getSize().height,
          "fill": "#ffffff",
          "stroke": null,
          "strokeWidth": 1,
          "strokeDashArray": null,
          "strokeLineCap": "butt",
          "strokeDashOffset": 0,
          "strokeLineJoin": "miter",
          "strokeMiterLimit": 4,
          "scaleX": 1,
          "scaleY": 1,
          "angle": 0,
          "flipX": false,
          "flipY": false,
          "opacity": 1,
          "shadow": null,
          "visible": true,
          "clipTo": null,
          "backgroundColor": "",
          "fillRule": "nonzero",
          "paintFirst": "fill",
          "globalCompositeOperation": "source-over",
          "transformMatrix": null,
          "skewX": 0,
          "skewY": 0,
          "rx": 0,
          "ry": 0,
          "selectable": false,
          "lockMovementX": true,
          "lockMovementY": true,
          "lockRotation": true,
          "hoverCursor": "default",
          "evented": false,
          "hclAllowUserChange": false
        },
        {
          "type": "image",
          "version": "2.4.6",
          "originX": "center",
          "originY": "center",
          "left": (this.getSize().width / 2),
          "top": (this.getSize().height / 2),
          "width": 0,
          "height": 0,
          "fill": "rgb(0,0,0)",
          "stroke": null,
          "strokeWidth": 0,
          "strokeDashArray": null,
          "strokeLineCap": "butt",
          "strokeDashOffset": 0,
          "strokeLineJoin": "miter",
          "strokeMiterLimit": 4,
          "scaleX": 1,
          "scaleY": 1,
          "angle": 0,
          "flipX": false,
          "flipY": false,
          "opacity": 1,
          "shadow": null,
          "visible": true,
          "clipTo": null,
          "backgroundColor": "",
          "fillRule": "nonzero",
          "paintFirst": "fill",
          "globalCompositeOperation": "source-over",
          "transformMatrix": null,
          "skewX": 0,
          "skewY": 0,
          "crossOrigin": "",
          "cropX": 0,
          "cropY": 0,
          "selectable": false,
          "lockMovementX": true,
          "lockMovementY": true,
          "lockRotation": true,
          "hoverCursor": "default",
          "evented": false,
          "src": "",
          "filters": [],
          "hclAllowUserChange": false
        }
      ],
      "hoverCursor": "move"
    }
  }

  getDuration() {
    return 10
  }

  getDefaultSize(): TemplateSize {
    return {
      width: 1920,
      height: 1080
    }
  }

  getSize(): TemplateSize {
    return this.size ? this.size : this.getDefaultSize()
  }

  setSize(size: TemplateSize) {
    this.size = size
  }

  getName() {
    return this.name
  }

  setName(name: string) {
    this.name = name
  }

  openMediaPicker(mediaCallback: (media: MediaForTemplate) => any): void {
    if (this.component != null) {
      this.ngZone.run(() => {
        this.component?.openMediaPicker().subscribe(media => mediaCallback(media as MediaForTemplate))
      })
    }
  }

  openSizeEditor(getSizeCallback: (vSize: TemplateSize) => any): void {
    if (this.component != null) {
      this.ngZone.run(() => {
        this.component?.openSizeEditor().subscribe(vSize => {
          this.setSize(vSize)
          getSizeCallback(vSize)
        })
      })
    }
  }

  hasPermission(permission: string, hasPermissionCallback: (perm: Boolean) => any): void {
    if (this.component != null) {
      this.ngZone.run(() => {
        this.component?.hasPermission(permission).subscribe(perm => {
          hasPermissionCallback(perm)
        })
      })
    }
  }

  openTemplateListPage(): void {
    if (this.component != null) {
      this.ngZone.run(() => {
        this.component?.openTemplateListPage()
      })
    }
  }

  setTemplateComponent(templateComp: TemplateComponent) {
    this.component = templateComp
  }

  setVariableText(variable: TemplateVariable, newText: string) {
    if (variable.type != "TEXT") {
      console.log(variable.name + " template variable is not of text type")
      return
    }

    const screenlabIFrame = this.getScreenlabTemplateEditorIFrame()
    if (screenlabIFrame) {
      screenlabIFrame.setTemplateObjectText(variable.objectIdx, newText)
    }
  }

  setVariableImgSrc(variable: TemplateVariable, media: MediaForTemplate) {
    if (variable.type != "IMAGE") {
      console.log(variable.name + " template variable is not of image type")
      return
    }

    const screenlabIFrame = this.getScreenlabTemplateEditorIFrame()
    if (screenlabIFrame) {
      screenlabIFrame.setTemplateObjectImageMedia(variable.objectIdx, media)
    }
  }

  selectVariableObject(variable: TemplateVariable) {
    const screenlabIFrame = this.getScreenlabTemplateEditorIFrame()
    if (screenlabIFrame) {
      screenlabIFrame.selectTemplateObject(variable.objectIdx)
    }
  }

  saveTemplate() {
    const screenlabIFrame = this.getScreenlabTemplateEditorIFrame()
    if (screenlabIFrame) {
      screenlabIFrame.saveTemplate()
    }
  }

  getScreenlabTemplateEditorIFrame(): ScreenlabTemplateEditorIframe | null {
    const iframeElt = this.document.getElementById("screenlab-template-editor") as HTMLIFrameElement;
    if (iframeElt) {
      return (iframeElt.contentWindow as any) as ScreenlabTemplateEditorIframe
    } else {
      return null
    }
  }
}
