import { HttpClient, HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Sort } from "@angular/material/sort"
import { HttpShowError, InvocationResult, QueryScope } from "hcl-lib"
import { environment } from "projects/hcl-portal/src/environments/environment"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { EncoderConfigDto, MediaDto } from "../../interfaces/dto/media-dto"
import { EncoderConfig, Media, MediaOrientation, MediaType } from "../../interfaces/media"
import { MediaDomainFilterEnum } from "../../interfaces/media-domain-filter-enum"

@Injectable({
  providedIn: "root"
})
export class MediaService {

  constructor(
    private httpClient: HttpClient
  ) { }

  @HttpShowError()
  getMediasWithPaging(
    page: number,
    perPage: number,
    customerId?: string,
    tagIds?: string[],
    types?: MediaType[],
    directoryFilter?: boolean,
    directoryId?: string,
    start?: Date | null,
    end?: Date | null,
    queryScope?: QueryScope,
    inheritableTemplate?: boolean,
    search?: string,
    domains?: string[],
    sort?: Sort,
    appIds?: string[],
    mediaDomainFilter: MediaDomainFilterEnum = MediaDomainFilterEnum.INCLUDE_WITHOUT_DOMAIN,
    orientations?: MediaOrientation[],
    system?: boolean
  ): Observable<InvocationResult> {
    const url = `${environment.mediaApiBaseUrl}/medias`
    let queryParams = new HttpParams()
      .set("perPage", perPage.toString())
      .set("page", page.toString())

    if (system !== undefined) {
      queryParams = queryParams.set("system", system)
    }

    if (customerId) {
      queryParams = queryParams.set("customerId", customerId)
    }
    if (tagIds) {
      tagIds.forEach(id => {
        queryParams = queryParams.append("tagIds", id)
      })
    }
    if (types) {
      types.forEach(type => {
        queryParams = queryParams.append("types", type.toString())
      })
    }
    if (directoryFilter) {
      queryParams = queryParams.append("directoryFilter", directoryFilter.toString())
    }
    if (directoryId) {
      queryParams = queryParams.append("directoryId", directoryId)
    }
    if (start) {
      queryParams = queryParams.append("start", start.toISOString())
    }
    if (end) {
      queryParams = queryParams.append("end", end.toISOString())
    }
    if (queryScope) {
      queryParams = queryParams.append("queryScope", queryScope)
    }
    if (inheritableTemplate) {
      queryParams = queryParams.append("inheritableTemplate", inheritableTemplate.toString())
    }
    if (search) {
      queryParams = queryParams.append("search", search)
    }
    if (domains) {
      domains.forEach(domain => {
        queryParams = queryParams.append("domains", domain)
      })
    }
    if (appIds) {
      appIds.forEach(appId => {
        queryParams = queryParams.append("appIds", appId)
      })
    }
    if (sort) {
      queryParams = queryParams.append("sort", `${sort.active}:${sort.direction}`)
    }
    if (mediaDomainFilter) {
      queryParams = queryParams.append("mediaDomainFilter", mediaDomainFilter)
    }
    if (orientations) {
      orientations.forEach(orientation => {
        queryParams = queryParams.append("orientations", orientation)
      })
    }
    return this.httpClient.get<InvocationResult>(url, { params: queryParams })
  }

  @HttpShowError()
  getMedia(
    id: string,
    customerId: string | undefined = undefined,
    queryScope: QueryScope | undefined = undefined,
    extraInformations: boolean | undefined = undefined
  ): Observable<Media> {
    const url = `${environment.mediaApiBaseUrl}/medias/${id}`
    let queryParams = new HttpParams()
    if (customerId) {
      queryParams = queryParams.set("customerId", customerId)
    }
    if (extraInformations) {
      queryParams = queryParams.set("extraInformations", extraInformations)
    }
    if (queryScope) {
      queryParams = queryParams.append("queryScope", queryScope)
    }
    return this.httpClient.get<InvocationResult>(url, { params: queryParams }).pipe(
      map(res => res.data)
    )
  }

  @HttpShowError(true)
  updateMedia(media: Media): Observable<Media | null> {
    const url = `${environment.mediaApiBaseUrl}/medias/${media.id}`
    const mediaDto: MediaDto = {
      id: media.id,
      customerId: media.customerId,
      name: media.name,
      public: media.public,
      type: media.type,
      status: media.status,
      url: media.url,
      thumbnailUrl: media.thumbnailUrl,
      s3FilePath: media.s3FilePath,
      tagIds: media.tagIds,
      displayTimeInSec: media.displayTimeInSec,
      validityStart: media.validityStart,
      validityEnd: media.validityEnd,
      validityDays: media.validityDays,
      directoryId: media.directoryId,
      inheritableTemplate: media.inheritableTemplate,
      visibleForDomains: media.visibleForDomains,
      visibleForAppIds: media.visibleForAppIds,
      errorKey: media.errorKey
    }
    return this.httpClient.put<InvocationResult>(url, mediaDto).pipe(
      map(res => res.data)
    )
  }

  @HttpShowError(true)
  createMedia(file: File, appIds: string[] | undefined = undefined): Observable<Media> {
    const url = `${environment.mediaApiBaseUrl}/medias`
    let queryParams = new HttpParams()
    if (appIds && appIds.length > 0) {
      queryParams = queryParams.append("appId", appIds[0])
    }
    const formData: FormData = new FormData()
    formData.append("file", file)
    return this.httpClient.post<InvocationResult>(url, formData, { params: queryParams }).pipe(
      map(res => res.data)
    )
  }

  @HttpShowError(true)
  createPosterMyWallMedia(file: File, designId: string): Observable<Media> {
    const url = `${environment.mediaApiBaseUrl}/medias/poster-my-wall`
    const formData: FormData = new FormData()
    formData.append("file", file)

    let queryParams = new HttpParams()
    if (designId) {
      queryParams = queryParams.append("pmwDesignId", designId)
    }

    return this.httpClient.post<InvocationResult>(url, formData, { params: queryParams }).pipe(
      map(res => res.data)
    )
  }

  @HttpShowError(true)
  updateMediaFile(id: string, file: File): Observable<Media> {
    const url = `${environment.mediaApiBaseUrl}/medias/${id}/file`
    const formData: FormData = new FormData()
    formData.append("file", file)
    formData.append("filename", file.name)
    formData.append("mimetype", file.type)
    return this.httpClient.put<InvocationResult>(url, formData).pipe(
      map(res => res.data)
    )
  }

  @HttpShowError(true)
  deleteMedia(id: string) {
    const url = `${environment.mediaApiBaseUrl}/medias/${id}`
    return this.httpClient.delete(url)
  }

  @HttpShowError(true)
  duplicateMedia(id: string, customerId: string, name: string): Observable<Media | null> {
    const url = `${environment.mediaApiBaseUrl}/medias/${id}/duplicate`
    let queryParams = new HttpParams()
    queryParams = queryParams.append("customerId", customerId)
    queryParams = queryParams.append("name", name)
    return this.httpClient.post<InvocationResult>(url, null, { params: queryParams }).pipe(
      map(res => res.data)
    )
  }

  @HttpShowError(true)
  forceEncodeMedia(id: string, encoderConfig: EncoderConfig | null = null): Observable<Media | null> {
    const url = `${environment.mediaApiBaseUrl}/medias/${id}/force-encode`
    return this.httpClient.post<InvocationResult>(url, encoderConfig as EncoderConfigDto).pipe(
      map(res => res.data)
    )
  }
}
